import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import EbookFormDialog from "./EbookFormDialog";
import CustomTable from "../CustomTable";
import { ebookFields } from "../../models";
import { useBookProvider } from "../BookContext";
import { BookAPI } from "../../api";
import * as actions from "../../constants/actions";

const EnhancedTableToolbar = (props) => {
  const { handleSearch, handleAddEbook, readOnly, numOfRecord } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle2">
          Ebooks <strong>({numOfRecord})</strong>
        </Typography>

        {!readOnly && (
          <Chip
            label="Add"
            color="primary"
            size="small"
            onClick={handleAddEbook}
          />
        )}
      </Stack>
      <TextField
        sx={{ width: "300px" }}
        size="small"
        label="Search by book title or author"
        onChange={(event) => handleSearch(event.target.value)}
      />
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

const initialValue = {
  bookId: "",
  title: "",
  author: "",
  type: "",
  edition: "",
  note: "",
};

export default function Ebook() {
  const { bookTypes, ebooks, dispatch, readOnly } = useBookProvider();
  const [records, setRecords] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState(initialValue);
  const [isEdit, setIsEdit] = React.useState(false);

  React.useEffect(() => {
    const mappedEbooks = ebooks.map((item) => {
      return {
        id: item.id,
        bookId: item.book?.book_id,
        title: item.book?.title,
        author: item.book?.author,
        edition: item.book?.edition,
        type: bookTypes.find((type) => type.id === item.book?.typeId)?.title,
        note: item.note,
        path: item.path,
      };
    });
    setRecords(mappedEbooks ? mappedEbooks : []);
  }, [ebooks, bookTypes]);

  React.useEffect(() => {
    setRows(records);
  }, [records]);

  const handleSearch = (key) => {
    const filterdRecords = records.filter((item) => {
      return (
        item.bookId?.toLowerCase()?.indexOf(key) > -1 ||
        item.title?.toLowerCase()?.indexOf(key) > -1 ||
        item.author?.toLowerCase()?.indexOf(key) > -1
      );
    });
    setRows(filterdRecords);
  };

  const handleRemove = async (id) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      await BookAPI.removeEbook(ebooks.find(item => item.id === id));
      dispatch({ type: actions.REMOVE_EBOOK, payload: id });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setRecord(records.find((item) => item.id === id));
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setIsEdit(false);
    setRecord(initialValue);
  };

  const handleAddEbookDialog = () => setOpen(true);

  const handleSubmit = async (data, file) => {
    const formData = new FormData();
    formData.append("bookId", data.bookId);
    formData.append("note", data.note);
    formData.append("file", file);

    if (data?.id) formData.append("id", data.id);
    if (data?.path) formData.append("path", data.path);

    try {
      dispatch({ type: actions.LOADING, payload: true });

      if (isEdit) {
        const response = await BookAPI.updateEbook(formData);
        dispatch({ type: actions.UPDATE_EBOOK, payload: response });
        setIsEdit(false);
      } else {
        const response = await BookAPI.addEbook(formData);
        dispatch({ type: actions.ADD_EBOOK, payload: response });
      }
      setOpen(false);
      setRecord(initialValue);
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          handleSearch={handleSearch}
          handleAddEbook={handleAddEbookDialog}
          readOnly={readOnly}
          numOfRecord={rows?.length}
        />
        <CustomTable
          headCells={ebookFields}
          rows={rows}
          handleRemove={handleRemove}
          handleEdit={handleEdit}
          isDelete={true}
          isEdit={true}
        />
      </Paper>
      {open && (
        <EbookFormDialog
          open={open}
          ebook={record}
          isEdit={isEdit}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </Box>
  );
}
