import { apiInstance, processApiResponse } from "./parent";

const BookAPI = {
  async getBooks() {
    const path = "/book/all";
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },

  async getBook(book_id) {
    const path = "/book/" + book_id;
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },

  async addBook(data) {
    const path = "/book";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async updateBook(data) {
    const path = "/book/update";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async removeBook(data) {
    const path = "/book/remove/" + data;
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },

  async deleteBook(data) {
    const path = "/book/delete/";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async rollBackBook(data) {
    const path = "/book/rollback";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async addType(data) {
    const path = "/book/type";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async getBookTypes() {
    const path = "/book/types";
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },

  async issueBook(data) {
    const path = "/book/issue";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async returnBook(data) {
    const path = "/book/return";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async renewBook(data) {
    const path = "/book/renew";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async getIssuedBooks() {
    const path = "/book/issued-books";
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },

  async addEbook(data) {
    const path = "/book/ebook";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async updateEbook(data) {
    const path = "/book/ebook/update";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async getEbooks() {
    const path = "/book/ebooks";
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },

  async removeEbook(data) {
    const path = "/book/ebook/remove";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response, "post", path);
  },

  async removeIssuedBook(id) {
    const path = "/book/issued-book/remove/" + id;
    const response = await apiInstance.get(path);
    return processApiResponse(response, "get", path);
  },
};

export default BookAPI;
