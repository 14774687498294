import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import EnhancedTableHead from "../EnhancedTableHead";
import ActionIcons from "../ActionIcons";
import { useBookProvider } from "../../BookContext";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function TableContentBook(props) {
  const {
    headCells,
    rows,
    handleRemove,
    handleEdit,
    handleBook,
    handleUser,
    handleAdd,
  } = props;
  const { readOnly } = useBookProvider();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleRemoveMode = (id) => () => handleRemove(id);
  const handleEditMode = (id) => () => handleEdit(id);
  const handleBookModal = (id) => () => handleBook(id);
  const handleUserModal = (id) => () => handleUser(id);
  const handleAddModal = (id) => () => handleAdd(id);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              readOnly={readOnly}
              isAction={true}
            />
            <TableBody>
              {rows
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      {!readOnly && (
                        <TableCell padding="checkbox">
                          <ActionIcons
                            row={row}
                            handleRemove={handleRemoveMode}
                            handleEdit={handleEditMode}
                            handleAddModal={handleAddModal}
                          />
                        </TableCell>
                      )}

                      <TableCell align="right">
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleBookModal(row.id)}
                        >
                          {row.book_id}
                        </Link>
                      </TableCell>
                      <TableCell align="left" onClick={handleBookModal(row.id)}>
                        {row.title}
                      </TableCell>
                      <TableCell align="left" onClick={handleBookModal(row.id)}>
                        {row.author}
                      </TableCell>
                      <TableCell align="left" onClick={handleBookModal(row.id)}>
                        {row.type?.title}
                      </TableCell>
                      <TableCell align="left">
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleUserModal(row.issuedTo)}
                          sx={{ textAlign: "left" }}
                        >
                          {row.user?.name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
