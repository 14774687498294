import React, { useEffect, useState } from "react";
import { issueFieldsCurrent, issueFieldsPrevious } from "../../models";
import { useBookProvider } from "../BookContext";
import { loggedInUser } from "../../api/actions";
import IssuedBookList from "./issuedBookList";
import GetUserDialog from "../Common/getUserDialog";
import PreviouslyIssuedList from "./previouslyIssuedList";
import utils from "../utils";
import BookAPI from "../../api/bookAPI";
import * as actions from "../../constants/actions";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import services from "../../services";
import CustomReportButton from "../CustomReportButton";
import { Stack } from "@mui/material";

export default function ReturnBook() {
  const { books, userid, issuedBooks, users, dispatch, readOnly } =
    useBookProvider();
  const [currentIssuedBooks, setCurrentIssuedBooks] = useState([]);
  const [previousIssuedBooks, setPreviousIssuedBooks] = useState([]);
  const [isOpen, setIsOpen] = React.useState(!userid ? true : false);
  const [userInfo, setUserInfo] = useState();
  const authUser = loggedInUser();

  useEffect(() => {
    if (!authUser?.librarian) handleUserSelect(authUser.userid);
  }, [authUser]);

  useEffect(() => {
    const user = users.find((item) => item.userid === userid);
    setUserInfo(user);

    const current = issuedBooks.filter(
      (item) => item.userId === user?.id && !item.return_date && item.active
    );
    const previous = issuedBooks.filter(
      (item) => item.userId === user?.id && item.return_date && item.active
    );

    const mappCurrent = utils.mappIssuedBook(current);
    const mappPrevious = utils.mappIssuedBook(previous);

    setCurrentIssuedBooks(mappCurrent ? mappCurrent : []);
    setPreviousIssuedBooks(mappPrevious ? mappPrevious : []);
  }, [userid, users, issuedBooks]);

  const handleReturn = async (data) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const mappedData = data.map((item) => ({
        id: item.id,
        bookId: item.bookId,
        return_date: Date.now(),
        returnById: authUser?.id,
      }));

      const response = await BookAPI.returnBook(mappedData);
      dispatch({ type: actions.RETURN_BOOKS, payload: response });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleRenew = async (data) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const mappedData = data.map((item) => ({
        id: item.id,
        bookId: item.bookId,
        renew_date: Date.now(),
      }));

      const response = await BookAPI.renewBook(mappedData);
      dispatch({ type: actions.RENEW_BOOKS, payload: response });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleUserSelect = (id) => {
    dispatch({ type: actions.USER_ID, payload: id });
    setIsOpen(false);
  };

  const handleClick = () => setIsOpen(true);

  const handleReport = () => {
    const mappedCurrent = currentIssuedBooks.map((item) => ({
      ...item,
      type: books.find((book) => book.id === item.bookId)?.type?.title,
    }));

    const mappedPrevious = previousIssuedBooks.map((item) => ({
      ...item,
      type: books.find((book) => book.id === item.bookId)?.type?.title,
    }));

    services.issuedReport(mappedCurrent, mappedPrevious);
  };

  return (
    <>
      {!readOnly && (
        <Paper sx={{ p: 1, mb: 1, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
          <Grid
            container
            spacing={2}
            sx={{ fontSize: "0.875rem" }}
            alignItems="center"
          >
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="body2" sx={{ pl: 1 }}>
                User Information:{" "}
                <strong>
                  {userInfo?.userid}, &nbsp; {userInfo?.name}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2} direction="row" sx={{ float: "right" }}>
                <CustomReportButton handleClick={handleReport} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClick}
                  sx={{ textTransform: "capitalize" }}
                  disableElevation
                >
                  Change User
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      )}

      <IssuedBookList
        headCells={issueFieldsCurrent}
        records={currentIssuedBooks}
        handleReturn={handleReturn}
        handleRenew={handleRenew}
      />

      <PreviouslyIssuedList
        headCells={issueFieldsPrevious}
        records={previousIssuedBooks}
      />

      {isOpen && (
        <GetUserDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUserSelect={handleUserSelect}
        />
      )}
    </>
  );
}
