import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthAPI } from "../../api";
import { ProcessAPIError, authenticate } from "../../api/actions";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function SignIn() {
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
  };

  const handleRedirect = () => {
    navigate("/library");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userid = data.get("userid");
    const password = data.get("password");

    try {
      const response = await AuthAPI.signin({
        userid: userid,
        password: password,
      });

      authenticate(response, () => {
        handleRedirect();
      });
    } catch (err) {
      await ProcessAPIError(err);
      setError(true);
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth="xs"
        PaperProps={{
          style: {
            maxWidth: "385px",
          },
        }}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 2,
              top: 2,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 4,
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  {error && (
                    <Alert severity="error">
                      Incorrect user id or password
                    </Alert>
                  )}

                  <TextField
                    autoFocus
                    required
                    fullWidth
                    id="userid"
                    label="User Id"
                    name="userid"
                    size="small"
                    margin="normal"
                  />
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    margin="normal"
                    size="small"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
