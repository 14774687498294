import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import NoticeFormDialog from "./NoticeFormDialog";
import NoticeAPI from "../../api/noticeAPI";
import CustomTable from "../CustomTable";
import { noticeFields } from "../../models";
import { useBookProvider } from "../BookContext";
import * as actions from "../../constants/actions";

const EnhancedTableToolbar = (props) => {
  const { handleSearch, handleAddNotice, readOnly, numOfRecord } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle2">
          Notices <strong>({numOfRecord})</strong>
        </Typography>

        {!readOnly && (
          <Chip
            label="Add"
            color="primary"
            size="small"
            onClick={handleAddNotice}
          />
        )}
      </Stack>
      <TextField
        sx={{ width: "300px" }}
        size="small"
        label="Search by title"
        onChange={(event) => handleSearch(event.target.value)}
      />
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

const initialValue = { title: "", note: "" };

export default function Notice(props) {
  const { dispatch, readOnly } = useBookProvider();
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [notices, setNotices] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [record, setRecord] = React.useState(initialValue);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await NoticeAPI.getNotices();
        setNotices(response ? response : []);
      } catch (error) {
        dispatch({ type: actions.ERROR, payload: error.message });
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    setRows(notices);
  }, [notices]);

  const handleSearch = (key) => {
    const filterdRecords = notices.filter(
      (item) => item.title.toLowerCase().indexOf(key) > -1
    );
    setRows(filterdRecords);
  };

  const handleRemove = async (id) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      await NoticeAPI.removeNotice(notices.find((item) => item.id === id));
      setNotices(notices.filter((item) => item.id !== id));
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setRecord(notices.find((item) => item.id === id));
    setOpen(true);
  };

  const handleAddNoticeDialog = () => {
    setOpen(true);
    setRecord(initialValue);
  };

  const handleSubmit = async (data, file) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("note", data.note);
    formData.append("file", file);

    if (data?.id) formData.append("id", data.id);
    if (data?.path) formData.append("path", data.path);

    try {
      dispatch({ type: actions.LOADING, payload: true });
      if (isEdit) {
        const response = await NoticeAPI.updateNotice(formData);
        const newData = notices.map((item) =>
          item.id === parseInt(response.id) ? response : item
        );
        setNotices(newData);
        setIsEdit(false);
      } else {
        const response = await NoticeAPI.addNotice(formData);
        setNotices([response, ...notices]);
      }
      setOpen(false);
      setRecord(initialValue);
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setIsEdit(false);
    setRecord(initialValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          handleSearch={handleSearch}
          handleAddNotice={handleAddNoticeDialog}
          readOnly={readOnly}
          numOfRecord={rows?.length}
        />
        <CustomTable
          headCells={noticeFields}
          rows={rows}
          handleRemove={handleRemove}
          handleEdit={handleEdit}
          isDelete={true}
          isEdit={true}
        />
      </Paper>
      {open && (
        <NoticeFormDialog
          open={open}
          notice={record}
          isEdit={isEdit}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </Box>
  );
}
