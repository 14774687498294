import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import utils from "../../utils";
import { UserAPI } from "../../../api";
import { user } from "../../../models";
import { useBookProvider } from "../../BookContext";
import { UserForm } from "../../Form";
import * as actions from "../../../constants/actions";

export default function AddUser(props) {
  const { handleTabSwitch } = props;
  const { dispatch } = useBookProvider();
  const [record, setRecord] = useState(user);
  const [file, setFile] = useState(null);

  const handleSubmit = async () => {
    const validMessage = utils.validateUser(record);
    if (validMessage) {
      dispatch({ type: actions.ERROR, payload: validMessage });
      return;
    }

    try {
      dispatch({ type: actions.LOADING, payload: true });
      const response = await UserAPI.addUser(
        utils.generateUserForm(record, file)
      );
      dispatch({
        type: actions.ADD_USER,
        payload: { ...response, roleId: parseInt(response.roleId) },
      });
      setRecord(user);
      setFile(null);
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: "Duplicate User Id" });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleCancel = () => handleTabSwitch(0);
  const handleFileChange = (fileRecord) => setFile(fileRecord);

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid sx={{ pb: 1 }}>
        <Typography variant="h6" gutterBottom component="div">
          Enter User Information
        </Typography>
      </Grid>
      <UserForm
        record={record}
        setRecord={setRecord}
        file={file}
        handleFileRecord={handleFileChange}
      />
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" sx={{ justifyContent: "center" }}>
            <Button variant="outlined" size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" size="small" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
