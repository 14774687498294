import axios from "axios";
import utils from "../components/utils";
import { BASE_URL } from "../config";
import { getCookie } from "./actions";

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

apiInstance.interceptors.request.use((config) => {
  const token = getCookie("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export { apiInstance };

export const processApiResponse = (response, method, uri) => {
  return new Promise((resolve, reject) => {
    if (response.status === 400 && response.data.statusCode === 404) {
      resolve([]);
    }
    if (response.status >= 200 && response.status < 400) {
      utils.isJsonString(response.data)
        ? resolve(JSON.parse(response.data))
        : resolve(response.data);
    } else if (method === "get" && response.status === 404) {
      resolve([]);
    } else if (response.status === 401) {
      reject({ message: "You are unauthenticated, please log in again" });
    } else {
      console.error(
        `HTTP ${method} call to ${uri} failed with response code ${response.status}`
      );
      reject({
        message: response.data?.Message || response?.data?.statusMessage,
      });
    }
  });
};
