import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import * as actions from "../../constants/actions";
import GetUserDialog from "../Common/getUserDialog";
import CustomTable0 from "../Tables/CustomTable0";
import IssueDialog from "./IssueDialog";
import CustomModal2 from "../Modals/CustomModal2";
import SelectedBooksTable from "../Common/SelectedBooksTable";
import utils from "../utils";
import { useBookProvider } from "../BookContext";
import { BookAPI } from "../../api";
import { bookFieldsIssue } from "../../models";
import { loggedInUser } from "../../api/actions";
import { issueFields } from "../../models";

const EnhancedTableToolbar = (props) => {
  const { numSelected, numOfBook, handleDisplay, handleSearch, handleClear } =
    props;
  const handleSerchKey = (event) => handleSearch(event.target.value);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1">
          Available Books <strong>({numOfBook})</strong>
        </Typography>

        {numSelected > 0 && (
          <>
            <Chip label={numSelected} color="success" size="small" />
            <Chip
              label="Clear"
              color="error"
              size="small"
              onClick={handleClear}
            />
            <Chip
              label="Verify & Issue"
              color="success"
              size="small"
              variant="outlined"
              onClick={handleDisplay}
            />
          </>
        )}
      </Stack>
      <TextField
        sx={{ minWidth: "250px" }}
        size="small"
        label="Enter book id or name"
        onChange={handleSerchKey}
      />
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDisplay: PropTypes.func.isRequired,
};

export default function IssueBook() {
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showItems, setShowItems] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [userIssuedRecords, setIssued] = React.useState(null);

  const { dispatch, users, books, userid, issuedBooks, readOnly } =
    useBookProvider();

  React.useEffect(() => {
    const mappBooks = books
      .filter((item) => !item.issuedTo && !item.written_off)
      .map((item) => ({ ...item, type_name: item.type?.title }));

    setRecords(mappBooks);
  }, [books]);

  React.useEffect(() => {
    setRows(records ? records : []);
  }, [records]);

  React.useEffect(() => {
    const userInfo = users.find((item) => item.userid === userid);
    const current = issuedBooks.filter(
      (item) => item.userId === userInfo?.id && !item.return_date && item.active
    );
    setUser(userInfo);
    setIssued(utils.mappIssuedBook(current));
  }, [userid, issuedBooks, users]);

  const handleDisplaySelectedItems = () => {
    setShowItems(true);
  };

  const handleClear = () => setSelected([]);

  const handleSearch = (key) => {
    const filterdRecords = records.filter((item) => {
      return (
        item.title.toLowerCase().indexOf(key) > -1 ||
        item.book_id.indexOf(key) > -1
      );
    });
    setRows(filterdRecords);
  };

  const handleIssue = async () => {
    setShowItems(false);
    const user_id = users.find((item) => item.userid === userid)?.id;
    const issuedData = selected.map((item) => ({
      bookId: item.id,
      userId: user_id,
      issuedById: loggedInUser()?.id,
    }));

    try {
      dispatch({ type: actions.LOADING, payload: true });

      const response = await BookAPI.issueBook({
        data: issuedData,
        userid: user_id,
      });

      setSelected([]);

      dispatch({
        type: actions.ISSUE_BOOKS,
        payload: { data: response, userid: user_id },
      });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleUserSelect = (id) => {
    dispatch({ type: actions.USER_ID, payload: id });
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const [viewIssuedBook, setViewIssuedBook] = React.useState(false);

  const handleDisplayIssuedBook = () => setViewIssuedBook(true);
  const closeViewIssuedBookModal = () => setViewIssuedBook(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ p: 1, mb: 1, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
        <Grid
          container
          spacing={1}
          sx={{ pl: 1, fontSize: "0.875rem" }}
          alignItems="center"
        >
          <Grid item xs={6}>
            {user && (
              <>
                User Info :{" "}
                <strong>
                  {user?.userid}, {user?.name}
                </strong>{" "}
                <br />
                Total number of issued books :
                <Chip
                  label={userIssuedRecords?.length}
                  color="success"
                  size="small"
                  onClick={handleDisplayIssuedBook}
                />
              </>
            )}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            {!readOnly && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClick}
                  sx={{ mr: 1, textTransform: "capitalize" }}
                  disableElevation
                >
                  Change User
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected?.length}
          numOfBook={rows?.length}
          handleDisplay={handleDisplaySelectedItems}
          handleClear={handleClear}
          handleSearch={handleSearch}
        />
        <CustomTable0
          headCells={bookFieldsIssue}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
        />
      </Paper>

      {showItems && (
        <IssueDialog
          isOpen={showItems}
          setIsOpen={setShowItems}
          selected={selected}
          setSelected={setSelected}
          handleIssue={handleIssue}
        />
      )}

      {isOpen && (
        <GetUserDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUserSelect={handleUserSelect}
        />
      )}

      {viewIssuedBook && (
        <CustomModal2
          title="Issued Records"
          size="large"
          open={viewIssuedBook}
          handleClose={closeViewIssuedBookModal}
        >
          <SelectedBooksTable
            records={userIssuedRecords}
            headCells={issueFields}
          />
        </CustomModal2>
      )}
    </Box>
  );
}
