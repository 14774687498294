import React, { useEffect, useState } from "react";
import { useBookProvider } from "../../BookContext";
import { UserAPI } from "../../../api";
import { UserForm } from "../../Form";
import CustomTable from "../../CustomTable";
import CustomModal from "../../Modals/CustomModal";
import CustomReportButton from "../../CustomReportButton";
import services from "../../../services";
import * as actions from "../../../constants/actions";
import utils from "../../utils";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function UserInfo(props) {
  const { roleId, userFields } = props;
  const { users, dispatch, readOnly, roles } = useBookProvider();
  const [records, setRecords] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => setFields(userFields), [userFields]);

  useEffect(() => {
    if (!roleId) {
      setRecords(
        users.map((item) => ({ ...item, role_name: item.role?.title }))
      );
    } else {
      setRecords(
        users
          .filter((item) => item.roleId === roleId)
          .map((item) => ({ ...item, role_name: item.role?.title }))
      );
    }
  }, [roleId, users]);

  const handleSearch = (event) => {
    const filterdData = users.filter((item) => {
      if (!roleId) {
        return (
          item.name.toLowerCase().indexOf(event.target.value) > -1 ||
          item.userid.indexOf(event.target.value) > -1
        );
      } else {
        return (
          item.roleId === roleId &&
          (item.name.toLowerCase().indexOf(event.target.value) > -1 ||
            item.userid.indexOf(event.target.value) > -1)
        );
      }
    });
    setRecords(filterdData);
  };

  const handleRemove = async (id) => {
    const userData = users.find((item) => item.id === id);
    try {
      dispatch({ type: actions.LOADING, payload: true });
      await UserAPI.updateUser({ ...userData, active: false });
      dispatch({ type: actions.REMOVE_USER, payload: userData?.id });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setRecord(users.find((item) => item.id === id));
  };

  const [record, setRecord] = useState();
  const [file, setFile] = useState(null);

  const closeEditModal = () => setIsEdit(false);

  const handleSubmit = async () => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const response = await UserAPI.updateUser(
        utils.generateUserForm(record, file)
      );
      dispatch({ type: actions.EDIT_USER, payload: response });
      dispatch({
        type: actions.SUCCESS,
        payload: "User updated successfully!",
      });
      setIsEdit(false);
      setRecord(null);
      setFile(null);
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: error.message });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleReport = () => {
    if (roleId)
      services.allusers(
        records,
        roles.filter((item) => item.id === roleId)
      );
    else services.allusers(records, roles);
  };

  const handleFileChange = (fileRecord) => setFile(fileRecord);

  return (
    <Box sx={{ maxWidth: "calc(100vw)" }}>
      <Paper sx={{ boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 2,
            pb: 1,
            pr: 1,
            pt: 1,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1">
              Number of User <strong>({records?.length})</strong>
            </Typography>
            {!readOnly && <CustomReportButton handleClick={handleReport} />}
          </Stack>

          <TextField
            sx={{ minWidth: "250px" }}
            size="small"
            label="Search by name or user id"
            onChange={handleSearch}
          />
        </Box>

        <CustomTable
          headCells={fields}
          rows={records}
          handleRemove={handleRemove}
          handleEdit={handleEdit}
          isDelete={true}
          isEdit={true}
        />
      </Paper>
      {isEdit && (
        <CustomModal
          title="Edit User"
          open={isEdit}
          handleClose={closeEditModal}
          handleSubmit={handleSubmit}
        >
          <UserForm
            record={record}
            setRecord={setRecord}
            handleFileRecord={handleFileChange}
          />
        </CustomModal>
      )}
    </Box>
  );
}
