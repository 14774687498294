const issueFieldsCurrent = [
  {
    id: "book_id",
    numeric: true,
    disablePadding: false,
    label: "Book Id",
    width: "auto",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    width: "200px",
  },
  {
    id: "author",
    numeric: false,
    disablePadding: false,
    label: "Author",
    width: "200px",
  },
  {
    id: "userid",
    numeric: true,
    disablePadding: false,
    label: "User Id",
    width: "auto",
  },
  {
    id: "issued_date",
    numeric: true,
    disablePadding: false,
    label: "Issue Date",
    width: "80px",
    type: "date",
  },
  {
    id: "issuedByName",
    numeric: false,
    disablePadding: false,
    label: "Issued By",
    width: "auto",
  },
];

export default issueFieldsCurrent;
