import cookie from "js-cookie";

// set cookie
export const setCookie = (key, value) => {
  // var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);  // 15 minute
  cookie.set(key, value, { expires: 0.25 });
};

// get cookie
export const getCookie = (key) => {
  return cookie.get(key);
};

// remove cookie
export const removeCookie = (key) => {
  cookie.remove(key);
};

// set user data to local storage
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// get user data to local storage
export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

// remove user data from local storage
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const ProcessAPIError = (error) => {
  if (error.response) {
    return error.response;
  } else if (error.request) {
    return { status: 404, data: { message: "Bad request!" } };
  } else {
    return { status: 404, data: { message: error.message } };
  }
};

// authenticate user by pass data to cookie and local storage
export const authenticate = (data, next) => {
  setCookie("token", data.token);
  setLocalStorage("user", data.user);
  next();
};

// check user authentication
export const useAuth = () => {
  if (getCookie("token") && getLocalStorage("user")) {
    return true;
  } else {
    return false;
  }
};

export const signOut = () => {
  removeCookie("token");
  removeLocalStorage("user");
  return;
};

export const loggedInUser = () => getLocalStorage("user");
