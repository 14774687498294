import * as React from "react";
import Box from "@mui/material/Box";
import CustomSlider from "./CustomSlider";
import LandingPageContent from "./LandingPageContent";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../api/actions";

export default function LandingPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (auth) handleRedirect();
    else navigate("/signin");
  };

  const handleRedirect = () => navigate("/library");

  return (
    <Box sx={{ width: "100%" }}>
      <CustomSlider />
      <LandingPageContent handleLogin={handleLogin} />
      <Outlet />
    </Box>
  );
}
