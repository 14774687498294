import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import SelectedBooksTable from "../Common/SelectedBooksTable";
import GetUserDialog from "../Common/getUserDialog";
import * as actions from "../../constants/actions";
import { bookFieldsUser } from "../../models";
import { useBookProvider } from "../BookContext";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function IssueDialog(props) {
  const { isOpen, setIsOpen, selected, setSelected, handleIssue } = props;
  const [open, setOpen] = React.useState(isOpen);
  const [userModal, setUserModal] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState();

  const { users, userid, dispatch } = useBookProvider();

  React.useEffect(() => {
    setUserInfo(users.find((item) => item.userid === userid));
  }, [userid]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const handleIssueClick = () => {
    if (!userid) setUserModal(true);
    else handleIssue();
  };

  const handleChangeUser = () => {
    setUserModal(true);
  };

  const handleUserSelect = (id) => {
    dispatch({ type: actions.USER_ID, payload: id });
    setUserModal(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          align="center"
        >
          Verify Book's Information
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Paper sx={{ mb: 1, mt: 1, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
            <Grid container>
              <Grid item xs={8}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ p: 1 }}
                >
                  {userInfo ? (
                    <>
                      User Information: &nbsp;
                      <strong>
                        {userInfo?.userid},&nbsp; {userInfo?.name},&nbsp;
                        {userInfo?.role?.title}
                      </strong>
                    </>
                  ) : (
                    <Grid item xs={10}></Grid>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleChangeUser}
                  sx={{ mb: 1, mr: 1, mt: 1, textTransform: "capitalize" }}
                  disableElevation
                >
                  Change User
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <SelectedBooksTable
            headCells={bookFieldsUser}
            records={selected}
            setRecords={setSelected}
            isAction={true}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            disableElevation
            size="small"
            variant="contained"
            endIcon={<SendIcon />}
            sx={{ ml: 1 }}
            onClick={handleIssueClick}
          >
            Issue
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {isOpen && (
        <GetUserDialog
          isOpen={userModal}
          setIsOpen={setUserModal}
          handleUserSelect={handleUserSelect}
        />
      )}
    </div>
  );
}
