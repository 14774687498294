import React, { useState } from "react";
import TableContentBook from "./TableContentBook";
import BookForm from "../../Form/BookForm";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import CustomModal from "../../Modals/CustomModal";
import CustomModal2 from "../../Modals/CustomModal2";
import UserDetailsForm from "../../Form/UserDetailsForm";
import * as actions from "../../../constants/actions";
import RecordDetails from "../../Form/RecordDetails";
import { bookFieldsAll } from "../../../models";
import { useBookProvider } from "../../BookContext";

export default function BookTable(props) {
  const { headCells, rows, handleRemove, handleSubmit } = props;
  const { users, dispatch } = useBookProvider();
  const [isRemove, setIsRemove] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isBook, setIsBook] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [removeData, setRemoveData] = useState(null);
  const [user, setUser] = useState();
  const [record, setRecord] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  const handleRemoveModal = (id) => {
    setRemoveData(rows.find((item) => item.id === id));
    setIsRemove(true);
  };

  const handleRemoveDecision = (decision) => (event) => {
    setIsRemove(false);
    if (!decision) return;
    if (removeData.issuedTo) {
      dispatch({
        type: actions.ERROR,
        payload:
          "This book is issued to " +
          removeData.user?.name +
          ", Please return it first.",
      });
    } else {
      if (decision) handleRemove(removeData.id);
      setRemoveData(null);
    }
  };

  const handleBook = (id) => {
    setIsBook(true);
    setRecord(rows.find((item) => item.id === id));
  };

  const handleUser = (id) => {
    setIsUser(true);
    setUser(users.find((item) => item.id === id));
  };

  const handleEditModal = (id) => {
    setIsEdit(true);
    setRecord(rows.find((item) => item.id === id));
  };

  const handleEditRecord = () => {
    handleSubmit(record, "edit");
    setIsEdit(false);
  };

  const handleAddRecord = () => {
    const book = {
      author: record.author,
      book_id: record.book_id,
      edition: record.edition,
      note: record.note,
      date: record.date ? record.date : null,
      price: record.price,
      publication_year: record.publication_year,
      publisher: record.publisher,
      shelf_no: record.shelf_no,
      title: record.title,
      typeId: record.typeId,
    };
    handleSubmit(book, "add");
    setIsAdd(false);
  };

  const handleAddModal = (id) => {
    setRecord(rows.find((item) => item.id === id));
    setIsAdd(true);
  };

  const closeEditModal = () => setIsEdit(false);
  const handleBookClose = () => setIsBook(false);
  const handleUserClose = () => setIsUser(false);
  const closeAddModal = () => setIsAdd(false);

  return (
    <>
      <TableContentBook
        headCells={headCells}
        rows={rows}
        handleBook={handleBook}
        handleUser={handleUser}
        handleEdit={handleEditModal}
        handleRemove={handleRemoveModal}
        handleAdd={handleAddModal}
      />

      {isBook && (
        <CustomModal2
          title="Book Details"
          open={isBook}
          handleClose={handleBookClose}
        >
          <RecordDetails headCells={bookFieldsAll} record={record} />
        </CustomModal2>
      )}

      {isUser && (
        <CustomModal2
          title="User Details"
          open={isUser}
          handleClose={handleUserClose}
        >
          <UserDetailsForm record={user} />
        </CustomModal2>
      )}

      {isEdit && (
        <CustomModal
          title="Edit Book"
          open={isEdit}
          handleClose={closeEditModal}
          handleSubmit={handleEditRecord}
        >
          <BookForm record={record} setRecord={setRecord} />
        </CustomModal>
      )}

      {isAdd && (
        <CustomModal
          title="Add Book"
          open={isAdd}
          handleClose={closeAddModal}
          handleSubmit={handleAddRecord}
        >
          <BookForm record={record} setRecord={setRecord} />
        </CustomModal>
      )}

      {isRemove && (
        <ConfirmationModal
          isOpen={isRemove}
          data={removeData}
          headCells={headCells}
          handleDecision={handleRemoveDecision}
        />
      )}
    </>
  );
}
