import { useContext } from "react";
import BookContext from "./BookContext";

const useBookProvider = () => {
  const {
    books,
    bookTypes,
    users,
    roles,
    issuedBooks,
    ebooks,
    userid,
    dispatch,
    readOnly
  } = useContext(BookContext);
  
  return {
    books,
    bookTypes,
    users,
    roles,
    issuedBooks,
    ebooks,
    userid,
    dispatch,
    readOnly
  };
};

export default useBookProvider;
