import React from "react";
import CustomTab from "../CustomTab";
import AllIssuedBooks from "./AllIssuedBooks";

export default function IssuedBooks() {
  const tabItems = [
    {
      title: "Current Issued Books",
      index: 0,
      component: () => <AllIssuedBooks current={true} />,
    },
    {
      title: "Previous Records",
      index: 1,
      component: () => <AllIssuedBooks current={false} />,
    },
  ];

  return <CustomTab tabs={tabItems} />;
}
