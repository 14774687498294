import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import CustomTable1 from "../Tables/CustomTable1";
import utils from "../utils";
import Typography from "@mui/material/Typography";
import RecordDetails from "../Form/RecordDetails";
import CustomModal2 from "../Modals/CustomModal2";
import { useBookProvider } from "../BookContext";
import {
  issueFields,
  issueFieldsCurrent,
  issueFieldsPrevious,
} from "../../models";

export default function AllIssuedBooks(props) {
  const { current } = props;
  const { issuedBooks } = useBookProvider();
  const [records, setRecords] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [fields, setFields] = React.useState(issueFields);

  React.useEffect(() => {
    setRows(records ? records : []);
  }, [records]);

  React.useEffect(() => {
    const currentRecords = utils.mappIssuedBook(
      issuedBooks.filter((item) => !item.return_date && item.active)
    );

    const previousRecords = utils.mappIssuedBook(
      issuedBooks.filter((item) => item.return_date && item.active)
    );

    setRecords(current ? currentRecords : previousRecords);
    setFields(current ? issueFieldsCurrent : issueFieldsPrevious);
  }, [issuedBooks, current]);

  const handleSearch = (key) => {
    const filterdRecords = records.filter((item) => {
      return (
        item.title?.toLowerCase()?.indexOf(key) > -1 ||
        item.book_id?.indexOf(key) > -1 ||
        item.user_name?.toLowerCase()?.indexOf(key) > -1 ||
        item.userid?.toLowerCase()?.indexOf(key) > -1
      );
    });
    setRows(filterdRecords);
  };

  const [view, setView] = React.useState(false);

  const handleDetails = (id) => {
    setView(true);
    setSelected(records.find((item) => item.id === id));
  };

  const closeViewModal = () => setView(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography variant="subtitle1">
            Number of currently issued books <strong>({rows.length})</strong>
          </Typography>

          <TextField
            sx={{ width: "350px" }}
            size="small"
            label="Enter book id, book name, user id or user name"
            onChange={(event) => handleSearch(event.target.value)}
          />
        </Box>

        <CustomTable1
          headCells={fields}
          rows={rows}
          handleClick={handleDetails}
        />
      </Paper>

      {view && (
        <CustomModal2
          title="Record Details"
          open={view}
          handleClose={closeViewModal}
        >
          <RecordDetails headCells={issueFields} record={selected} />
        </CustomModal2>
      )}
    </Box>
  );
}
