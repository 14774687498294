import React from "react";
import CustomTab from "../CustomTab";
import AllWrittenOffBook from "./AllWrittenOffBook";
import BookByTypes from "./BookByTypes";
import ReportWrittenOffBooks from "./ReportWrittenOffBooks";

export default function WrittenOffBooks() {
  const tabItems = [
    {
      title: "All Written Off Books",
      index: 0,
      component: () => <AllWrittenOffBook />,
    },
    {
      title: "Book By Types",
      index: 1,
      component: () => <BookByTypes />,
    },
    {
      title: "View Report",
      index: 2,
      component: () => <ReportWrittenOffBooks />,
    },
  ];

  return <CustomTab tabs={tabItems} />;
}
