import * as React from "react";
import BookTypes from "./BookTypes";
import AllBook from "./AllBook";
import AddBook from "./AddBook";
import CustomTab from "../CustomTab";
import { useBookProvider } from "../BookContext";

export default function Book() {
  const { readOnly } = useBookProvider();
  const [tab, setTab] = React.useState(0);
  const [bookTypeId, setBookTypeId] = React.useState("");

  const tabItems0 = [
    {
      title: "All Book",
      index: 0,
      component: () => <AllBook bookTypeId={bookTypeId} />,
    },
    {
      title: "Book Types",
      index: 1,
      component: () => <BookTypes handleTabSwitch={handleTabSwitch} />,
    },
  ];

  let tabItems = tabItems0;
  if (!readOnly)
    tabItems = [
      ...tabItems0,
      {
        title: "Add Book",
        index: 2,
        component: () => <AddBook handleTabSwitch={handleTabSwitch} />,
      },
    ];

  const handleTabSwitch = (tabId, typeId) => {
    setTab(tabId);
    setBookTypeId(typeId);
  };

  const handleTabChange = (value) => {
    setTab(value);
    setBookTypeId("");
  };

  return (
    <CustomTab
      defaultTab={tab}
      tabs={tabItems}
      handleTabChange={handleTabChange}
    />
  );
}
