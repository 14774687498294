const bookFieldsUser = [
  {
    id: "book_id",
    numeric: false,
    disablePadding: false,
    label: "Book Id",
    width: "auto",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    width: "200px",
  },
  {
    id: "author",
    numeric: false,
    disablePadding: false,
    label: "Author",
    width: "200px",
  },
  {
    id: "type_name",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "100px",
  },
  {
    id: "publisher",
    numeric: false,
    disablePadding: false,
    label: "Publisher",
    width: "100px",
  },
  {
    id: "shelf_no",
    numeric: true,
    disablePadding: false,
    label: "Shelf No.",
    width: "auto",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
    width: "auto",
  },
  {
    id: "note",
    numeric: false,
    disablePadding: true,
    label: "Note",
    width: "auto",
  },
];

export default bookFieldsUser;
