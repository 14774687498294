import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dropdown from "./dropdown";
import { useBookProvider } from "../BookContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function BookForm(props) {
  const { record, setRecord } = props;
  const { bookTypes } = useBookProvider();
  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(record?.date ? dayjs(record?.date) : null);
  }, [record]);

  const handleTypeSelect = (typeId) => {
    setRecord({ ...record, typeId: typeId });
  };

  const handleChange = (field) => (event) => {
    setRecord({ ...record, [field]: event.target.value });
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);

    if (newValue) {
      setRecord({ ...record, date: newValue.format("YYYY-MM-DD") });
    } else {
      setRecord({ ...record, date: null });
    }
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="string"
          size="small"
          label="Book Id"
          variant="outlined"
          value={record.book_id ? record.book_id : ""}
          onChange={handleChange("book_id")}
          error={!record.book_id ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          defaultValue={record.typeId}
          dropdownItems={bookTypes}
          handleTypeSelect={handleTypeSelect}
          pickListLable="Book Type"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="string"
          size="small"
          label="Book Title"
          variant="outlined"
          value={record.title ? record.title : ""}
          onChange={handleChange("title")}
          error={!record.title ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Author"
          variant="outlined"
          value={record.author ? record.author : ""}
          onChange={handleChange("author")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Price"
          variant="outlined"
          value={record.price ? record.price : ""}
          onChange={handleChange("price")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Publisher"
          variant="outlined"
          value={record.publisher ? record.publisher : ""}
          onChange={handleChange("publisher")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Shelf Number"
          variant="outlined"
          value={record.shelf_no ? record.shelf_no : ""}
          onChange={handleChange("shelf_no")}
        />
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            inputFormat="DD/MM/YYYY"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: "100%",
                  ".MuiInputLabel-root": { top: "-8px" },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label="Note"
          variant="outlined"
          multiline
          rows={4}
          value={record.note ? record.note : ""}
          onChange={handleChange("note")}
        />
      </Grid>
    </Grid>
  );
}
